import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { LightProbe, Raycaster } from 'three';
// not working properly on ios
// check if page opened on mobile
const isMobile = ('ontouchstart' in document.documentElement && navigator.userAgent.match(/Mobi/));
if (isMobile) {
    console.log('ismobile');
  }
// fn to generate mirrored text
export const generateStripeTexture = (
    text,
    colors = { main: "#ffa1a1", second: "blue" }
  ) => {
    const copyAmount = 2;
    const canvasSize = 640;
    const fontSize = canvasSize / copyAmount;
    const fontStyle = `Bold ${fontSize}px Arial`;
  
    const bitmap = document.createElement("canvas");
    const g = bitmap.getContext("2d");
    g.font = fontStyle;
    bitmap.width = g.measureText(text).width;
    bitmap.height = fontSize * 2;
  
    const generateTextRow = (shift, i) => {
      // background
      g.fillStyle = Object.values(colors)[i];
      g.fillRect(0, shift * i, bitmap.width, bitmap.height);
  
      // text
      g.font = `Bold ${fontSize}px Arial`;
      // g.fillStyle = Object.values(colors)[i];
      g.fillText(text, 0, fontSize * i - 40);
      g.fillStyle = Object.values(colors)[0];
    };
  
    Array(copyAmount + 1)
      .fill(0)
      .forEach((item, i) => {
        generateTextRow(bitmap.height / 2, i);
      });
  
    // text
    return bitmap;
  };

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()
scene.background = new THREE.Color(0xffffff);

// materials and texture
const colour1 = {
    main: "#3A3A3A",
    second: "#DEDEDE"
};

const colour2 = {
    main: "#3A3A3A",
    second: "#DEDEDE"
};

const colour3 = {
    main: "#3A3A3A",
    second: "#DEDEDE"
};

// WORK BALL
const ballTexture1 = new THREE.Texture(
    generateStripeTexture('WORKS ', colour1)
);

ballTexture1.needsUpdate = true;
ballTexture1.wrapS = THREE.RepeatWrapping;
ballTexture1.wrapT = THREE.RepeatWrapping;
ballTexture1.repeat.set(4,4);

const ballMaterial1 = new THREE.MeshPhongMaterial({
    map: ballTexture1,
    transparent: true,
    opacity: 1.0
});



// ABOUT BALL
const ballTexture2 = new THREE.Texture(
    generateStripeTexture('ABOUT ', colour2)
);
ballTexture2.needsUpdate = true;
ballTexture2.wrapS = THREE.RepeatWrapping;
ballTexture2.wrapT = THREE.RepeatWrapping;
ballTexture2.repeat.set(4,4);

const ballMaterial2 = new THREE.MeshPhongMaterial({
    map: ballTexture2,
    transparent: true,
    opacity: 1.0
});


// CONTACT BALL
const ballTexture3 = new THREE.Texture(
    generateStripeTexture('CONTACT ', colour3)
);
ballTexture3.needsUpdate = true;
ballTexture3.wrapS = THREE.RepeatWrapping;
ballTexture3.wrapT = THREE.RepeatWrapping;
ballTexture3.repeat.set(4,4);

const ballMaterial3 = new THREE.MeshPhongMaterial({
    map: ballTexture3,
    transparent: true,
    opacity: 1.0
});


/**
 * Objects
 */
const geometry = new THREE.SphereGeometry(1, 32,32);

// ABOUT
const ball2 = new THREE.Mesh(geometry, ballMaterial2);
ball2.rotation.z = 45;
ball2.rotation.x = 550;
ball2.rotation.y = -206;

// CONTACT
const ball3 = new THREE.Mesh(geometry, ballMaterial3);
ball3.rotation.z = 45;
ball3.rotation.x = 550;
ball3.rotation.y = -206;
ball3.position.x = 4

// WORKS
const ball1 = new THREE.Mesh(geometry, ballMaterial1);
ball1.position.x = - 4
ball1.rotation.z = 45;
ball1.rotation.x = 550;
ball1.rotation.y = -206;

scene.add(ball1, ball2, ball3)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

// CURSOR
const cursor = new THREE.Vector2()
window.addEventListener('mousemove', (event) => {
    // normalise values 
    cursor.x = event.clientX / sizes.width * 2 - 1
    cursor.y = -(event.clientY / sizes.height * 2 - 1)
})

const closeModalButton = document.querySelectorAll('[data-close-button]')
const overlay = document.getElementById('overlay')

window.addEventListener('click', () => {

    if(currentIntersect)
    {
        if(currentIntersect.object === ball1)
        {
            window.open('https://github.com/pinkhit?tab=repositories')
            console.log('works')
        }
        else if(currentIntersect.object === ball2)
        {
            // open modal tagged with id "about"
            const modal = document.getElementById("about")
            openModal(modal)
            console.log('about')
        }
        else if(currentIntersect.object === ball3)
        {
            window.open('https://linktr.ee/khitgoh')
            console.log('contact')
        }

    }
})

// function to open modal, sets modal to active
function openModal(modal) {
    if (modal == null) return;
    modal.classList.add('active');
    overlay.classList.add('active');
}

// function to close modal by clicking on overlay
overlay.addEventListener('click', () => {
    const modals = document.querySelectorAll('.modal.active');
    modals.forEach(modal =>{
        closeModal(modal);
    })
})

// function to close modal, removes active status on clicking X
closeModalButton.forEach(button => {
    button.addEventListener('click', () => {
        const modal = button.closest('.modal');
        closeModal(modal);
    })
})
function closeModal(modal) {
    if (modal == null) return;
    modal.classList.remove('active');
    overlay.classList.remove('active');
}

/**
 * Raycaster
 */
 const raycaster = new THREE.Raycaster();

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.z = 12;
scene.add(camera);

// light
const light = new THREE.AmbientLight(
    "rgb(100%, 100%, 100%)", 1, 0
);
light.position.set(-200,30,-30);
scene.add(light);

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
let currentIntersect = null
const tick = () => {
    
    raycaster.setFromCamera(cursor, camera);
    const balls = [ball1, ball2, ball3]
    const intersects = raycaster.intersectObjects(balls)

    for (const ball of balls)
    {
        ball.material.opacity = 1.0
        ball.material.emissive.setHex(0x000000)

        
    }
    for (const intersect of intersects)
    {
        if (!isMobile) {
        intersect.object.material.opacity = 0.8
        intersect.object.material.emissive.setHex(0x05ff10)
        }
    }

    if(intersects.length)
    {
        // if(currentIntersect === null)
        // {
        //     console.log('mouse enter')
        // }
        currentIntersect = intersects[0]
    }
    else
    {
        // if(currentIntersect)
        // {
        //     console.log('mouse leave')
        // }
        currentIntersect = null
    }
    

    window.requestAnimationFrame(tick);
    ballTexture1.offset.y -=0.01;
    ballTexture2.offset.y -=0.01;
    ballTexture3.offset.y -=0.01;
    camera.rotation.z += 0.002;
    renderer.render(scene, camera);
    
}

tick();
